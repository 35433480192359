// ########## VARIABLES #########
$borderColor: #fff;

// ########## FONTS #########
// -> font montserrat
@font-face {
    font-family: "montserrat";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../fonts/montserrat.woff") format("woff"),
        url("../fonts/montserrat.woff2") format("woff2");
}

// -> font comfortaa
@font-face {
    font-family: "comfortaa";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("../fonts/comfortaa.woff") format("woff"),
        url("../fonts/comfortaa.woff2") format("woff2");
}

// ########## GENERAL #########
* {
    font-family: "montserrat" !important;
}

.head-font {
    font-family: "comfortaa" !important;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow-x: "hidden";

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(125, 98, 212, 0.7);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(125, 98, 212, 0.6);
    }
}

.app-btn {
    border: 1px solid $borderColor !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-radius: 22px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
}
.app-btn-2 {
    border: 1px solid $borderColor !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    font-weight: bold !important;
}
a {
    text-decoration: none !important;
}

.error-text {
    font-size: 12px;
    color: red;
}

.animated-bg {
    background: linear-gradient(
        -45deg,
        rgb(252, 163, 17, 0.2),
        rgba(125, 98, 212, 0.2),
        rgb(252, 163, 17, 0.2),
        rgba(125, 98, 212, 0.2)
    );
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;
}

.animated-bg-2 {
    background: linear-gradient(
        -25deg,
        rgba(2, 123, 189, 0.5),
        rgba(0, 238, 118, 0.2),
        rgba(125, 98, 212, 0.5),
        rgba(252, 163, 17, 0.5)
    );
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;
}

.animated-bg-3 {
    background: linear-gradient(
        45deg,
        rgb(252, 163, 17, 0.2),
        rgba(125, 98, 212, 0.2),
        rgb(252, 163, 17, 0.2),
        rgba(125, 98, 212, 0.2)
    );
    background-size: 200% 200%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
